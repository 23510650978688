import {create} from "zustand";
import {toast} from "react-toastify";
import {baseApi} from "../api/axios";

export const useAuthStore = create((set, get) => {

    return {
//
        //
        checkPlayer:true,// zstore=true // other=>false
        icon: 'usd',//zstore=>syr // extra= coins // other usd
        online: false,//smart,royal=>true
        gradient: true,//extra=>false
        user: null,
        settings: null,
        setUser: (user) => {
            set({user})

        },
        logOut: () => {
            set({user: null})

        },
        setSettings: (settings) => {
            set({settings})

        },
        copyText: (text) => {

            // Copy the text inside the input
            navigator.clipboard.writeText(text).then(function () {
                toast.success('تم النسخ إلى الحافظة')
            }).catch(function (error) {
                toast.error('خطأ في النسخ: ' + error);
            });
        },
        formatCurrency: (number) => {
            const currency = 'usd';//zstore=>SYP // other usd
            const formatter = new Intl.NumberFormat('en-US', {
                //  style: 'currency',
                currency: currency,
                //  currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: currency === 'SYP' ? 0 : 2,
                maximumFractionDigits: currency === 'SYP' ? 0 : 2,
            });
            if (currency === 'SYP') {
                // return formatter.format(number);
                return formatter.format(number)
            }
            return `${formatter.format(number)} $`


        },
        //     const currency = 'syr';//zstore=>syp // extra= coins // other usd
        //     const formatter = new Intl.NumberFormat('en-US', {
        //       //  style: 'currency',
        //         currency: currency,
        //       //  currencyDisplay: 'narrowSymbol',
        //         minimumFractionDigits: currency === 'SYP' ? 0 : 2,
        //         maximumFractionDigits: currency === 'SYP' ? 0 : 2,
        //     });
        //     if (currency === 'SYP') {
        //         // return formatter.format(number);
        //         return formatter.format(number)
        //     }
        //     return `${formatter.format(number)} $`
        //
        //
        // }

        getPlayerName:async(productId,playerId)=>{
            try{
              const response=await baseApi.post('/check-player',{player_id:playerId,product_id:productId})
              //  console.log(response.data.player_name)
                if(response.data?.player_name){
                    return response.data?.player_name
                }
                return 'Not Found'
            }catch (e){
                toast.error(e.response?.data?.msg??'خطأ في الطلب')
            }
        }
    }
})